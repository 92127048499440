<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <b-card header-tag="header" footer-tag="footer">
          <b-card-text>
            <div>
              <b-form-group label="Categoría">
                <a-multiselect
                  v-model="form.object_category"
                  track-by="id"
                  label="name"
                  placeholder="Seleccione categoría"
                  :options="categories"
                  :allow-empty="true"
                >
                </a-multiselect>
              </b-form-group>

              <b-form-group label="Código">
                <b-form-input v-model="form.code" required></b-form-input>
              </b-form-group>

              <b-form-group label="Abreviatura">
                <b-form-input v-model="form.abbrev" required></b-form-input>
              </b-form-group>

              <b-form-group label="Descripción">
                <b-form-input
                  v-model="form.description"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Cliente">
                <a-multiselect
                  v-model="form.object_client"
                  track-by="id"
                  label="social_name"
                  placeholder="Seleccione cliente"
                  :options="clients"
                  :allow-empty="true"
                >
                </a-multiselect>
              </b-form-group>

              <b-form-group label="Responsable del proyecto">
                <a-multiselect
                  v-model="form.object_manager"
                  track-by="id"
                  label="full_name"
                  placeholder="Seleccione responsable"
                  :options="managers"
                  :allow-empty="true"
                >
                </a-multiselect>
              </b-form-group>
            </div>
          </b-card-text>

          <template v-slot:footer>
            <b-button size="sm" variant="primary" @click="saveItem()">
              OK
            </b-button>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import "vue-multiselect/dist/vue-multiselect.min.css";

export default {
  data() {
    return {
      categories: [],
      clients: [],
      managers: [],
      form: { object_category: {} }
    };
  },
  methods: {
    saveItem() {
      this.form.client = this.form.object_client.id;
      this.form.manager = this.form.object_manager.id;
      this.form.category = this.form.object_category.id;
      if ("id" in this.$route.params) {
        ApiService.patch(`api/project/${this.$route.params.id}/`, this.form)
          .then(() => {
            this.$bvToast.toast("Se ha creado el item correctamente", {
              title: `Éxito`,
              variant: "success",
              solid: true
            });
            setTimeout(
              () =>
                this.$router.push({
                  name: "maintenances_project"
                }),
              1000
            );
          })
          .catch(() => {
            this.$bvToast.toast("Ha ocurrido un error", {
              title: `Error`,
              variant: "danger",
              solid: true
            });
          });
      } else {
        ApiService.post("api/project/", this.form)
          .then(() => {
            this.$bvToast.toast("Se ha creado el item correctamente", {
              title: `Éxito`,
              variant: "success",
              solid: true
            });
            setTimeout(
              () =>
                this.$router.push({
                  name: "maintenances_project"
                }),
              1000
            );
          })
          .catch(() => {
            this.$bvToast.toast("Ha ocurrido un error", {
              title: `Error`,
              variant: "danger",
              solid: true
            });
          });
      }
    },
    getCategories() {
      ApiService.get("api/category").then(response => {
        this.categories = response.data;
      });
    },
    getManagers() {
      ApiService.get("api/user").then(response => {
        console.log(response.data);
        this.managers = response.data;
      });
    },
    getClients() {
      ApiService.get("api/client").then(response => {
        this.clients = response.data;
      });
    },
    getProject() {
      ApiService.get(`api/project/${this.$route.params.id}`).then(response => {
        console.log(response.data);
        this.form = response.data;
        this.form.object_category = this.categories[
          this.findSelectIndex(this.categories, this.form.category)
        ];
        this.form.object_manager = this.managers[
          this.findSelectIndex(this.managers, this.form.manager)
        ];
        this.form.object_client = this.clients[
          this.findSelectIndex(this.clients, this.form.client)
        ];
      });
    },
    findSelectIndex(arr, field) {
      return this.custom_lodash.findIndex(arr, ["id", field]);
    }
  },
  created() {
    this.getCategories();
    this.getClients();
    this.getManagers();
  },
  mounted() {
    if ("id" in this.$route.params) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Mantenimientos" },
        { title: "Proyectos de ingeniería" },
        { title: "Actualizar" }
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Mantenimientos" },
        { title: "Proyectos de ingeniería" },
        { title: "Crear" }
      ]);
    }
  },
  watch: {
    categories: function() {
      if ("id" in this.$route.params) {
        console.log("Editando proyecto");
        this.getProject();
      }
    },
    clients: function() {
      if ("id" in this.$route.params) {
        console.log("Editando proyecto");
        this.getProject();
      }
    },
    managers: function() {
      if ("id" in this.$route.params) {
        console.log("Editando proyecto");
        this.getProject();
      }
    }
  }
};
</script>
